<template>
  <div class="layout--main main-vertical navbar-floating footer-static">
    <div id="content-area" class="content-area-full">
    <div class="relative">
      <div class="vx-navbar-wrapper navbar-full">
        <header class="vs-navbar vx-navbar navbar-custom navbar-skelton vs-navbar-null bg-white">
          <div class="vs-con-items justify-start">
            <div class="fill-current text-primary">
              <div class="site-logo self-center">
                <img src="@/assets/images/logo/rellopay-logo.svg" alt="Rello" v-if="appName ==='RelloPay'" />
                <img src="@/assets/images/logo/flippay-logo.svg" alt="FlipPay" v-if="appName ==='FlipPay'" />
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="router-view">
        <div class="router-content">
          <div class="mt-12 md:mt-16 lg:mt-24">
            <h2 class="text-xl md:text-2xl lg:text-3xl mb-4 md:mb-6">Page has expired!</h2>
            <p>Please <a href="" class="text-link">contact</a> us to get a new link.</p>
          </div>
        </div>
      </div>
    </div>
    <footer class="the-footer flex-wrap justify-between merchant-foot"></footer>
    </div>
  </div>
</template>
<script>
export default{
   data() {
    return {
      appName: process.env.VUE_APP_NAME ||"FlipPay"
    };
  }
}
</script>